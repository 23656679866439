/* eslint-disable no-undef, react/prop-types, react/no-danger */
import React from 'react';
import unified from 'unified';
import markdown from 'remark-parse';
import { graphql } from 'gatsby';
import html from 'remark-html';
import Layout from '../../components/furniture/Layout/Layout';
import Seo from '../../components/Seo';

const Template = ({ data, location }) => {
  const {
    airtable: { data: post },
  } = data;
  return (
    <Layout location={location}>
      <Seo title={post.title} />
      <h1>{post.title}</h1>
      <div>{post.date}</div>
      <h5>Written by {post.author}</h5>
      <div
        dangerouslySetInnerHTML={{
          __html: unified()
            .use(markdown)
            .use(html)
            .processSync(post.body),
        }}
      />
    </Layout>
  );
};

export const pageQuery = graphql`
  query BlogPostByPath($slug: String) {
    airtable(data: { slug: { eq: $slug } }) {
      data {
        slug
        title
        author
        body
        date
      }
    }
  }
`;

export default Template;
